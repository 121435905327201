import type { Scale, SignalRef, Spec } from 'vega';

import { isFinite, isNil, isNumber, isPlainObject, isString, map } from '@stlc/lodash';
import { StlcChartAxis, StlcDefaultSpecOptions, StlcPitchZone } from '@stlc/vega/types';

export const BUSCH_STADIUM_VENUE_ID = 2889;

export const defaultFont = '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif';

export const boldFont = '"Roboto Bold", "Helvetica Neue", Helvetica, Arial, sans-serif';

export const pitchTypes = [
    { value: 'FA', name: 'Fastball', abbrev: 'FA', color: '#dc2626', class: 'fastball' },
    { value: 'FF', name: 'Four-seam FB', abbrev: 'FA', color: '#dc2626', class: 'fastball' },
    { value: 'FT', name: 'Two-seam FB', abbrev: 'SI', color: '#f97316', class: 'fastball' },
    { value: 'SI', name: 'Sinker', abbrev: 'SI', color: '#f97316', class: 'fastball' },
    { value: 'FC', name: 'Cutter', abbrev: 'CT', color: '#f472b6', class: 'fastball' },
    { value: 'SL', name: 'Slider', abbrev: 'SL', color: '#16a34a', class: 'breaking-ball' },
    { value: 'SW', name: 'Sweeper', abbrev: 'SW', color: '#06b6d4', class: 'breaking-ball' },
    { value: 'CU', name: 'Curveball', abbrev: 'CB', color: '#9333ea', class: 'breaking-ball' },
    { value: 'KN', name: 'Knuckleball', abbrev: 'KN', color: '#ea9333', class: 'breaking-ball' },
    { value: 'EP', name: 'Eephus', abbrev: 'EP', color: '#a34a16', class: 'breaking-ball' },
    { value: 'FO', name: 'Forkball', abbrev: 'FO', color: '#bcbd22', class: 'breaking-ball' },
    { value: 'CS', name: 'Slow Curve', abbrev: 'CS', color: '#9a3412', class: 'breaking-ball' },
    { value: 'KC', name: 'Knuckle Curve', abbrev: 'KC', color: '#9333ea', class: 'breaking-ball' },
    { value: 'SV', name: 'Slurve', abbrev: 'SV', color: '#9333ea', class: 'breaking-ball' },
    { value: 'GY', name: 'Gyroball', abbrev: 'GY', color: '#9a3412', class: 'breaking-ball' },
    { value: 'CH', name: 'Changeup', abbrev: 'CH', color: '#2563eb', class: 'offspeed' },
    { value: 'FS', name: 'Splitter', abbrev: 'SP', color: '#bcbd22', class: 'offspeed' },
    { value: 'SC', name: 'Screwball', abbrev: 'SC', color: '#33ea93', class: 'offspeed' },
    { value: 'unk', name: 'unk', abbrev: 'UN', color: '#737373' },
    { value: 'UN', name: 'Unknown', abbrev: 'UN', color: '#737373' },
];

export const pitchTypeValues = map(pitchTypes, 'value');

interface RulebookZone {
    y: number;
    y2: number;
    width: number;
}

export const rulebookZone: RulebookZone = {
    y: 3.4,
    y2: 1.6,
    width: 17 / 12,
};

const pitchZoneOffset = 0.1;

export const pitchZones: StlcPitchZone[] = [
    {
        value: 'OOZ-Far-Middle-Up',
        group: 'Out-Of-Zone',
        text: 'Far Above Zone',
        data: [
            { x: -rulebookZone.width / 2, y: rulebookZone.y + 0.9 },
            { x: rulebookZone.width / 2, y: rulebookZone.y + 0.9 },
            { x: rulebookZone.width / 2, y: rulebookZone.y + 0.5 },
            { x: -rulebookZone.width / 2, y: rulebookZone.y + 0.5 },
            { x: -rulebookZone.width / 2, y: rulebookZone.y + 0.9 },
        ],
    },
    {
        value: 'OOZ-Middle-Up',
        group: 'Out-Of-Zone',
        text: 'Above Zone',
        data: [
            { x: -(rulebookZone.width / 3), y: rulebookZone.y + 0.5 },
            { x: rulebookZone.width / 3, y: rulebookZone.y + 0.5 },
            { x: rulebookZone.width / 3, y: rulebookZone.y + 0.1 },
            { x: -(rulebookZone.width / 3), y: rulebookZone.y + 0.1 },
            { x: -(rulebookZone.width / 3), y: rulebookZone.y + 0.5 },
        ],
    },
    {
        value: 'OOZ-Left-Up',
        group: 'Out-Of-Zone',
        text: 'Left Up',
        data: [
            { x: -1.4, y: rulebookZone.y + 0.5 },
            { x: -(rulebookZone.width / 3), y: rulebookZone.y + 0.5 },
            { x: -(rulebookZone.width / 3), y: rulebookZone.y + 0.1 },
            { x: -0.9, y: rulebookZone.y + 0.1 },
            { x: -0.9, y: rulebookZone.y - 0.15 },
            { x: -1.4, y: rulebookZone.y - 0.15 },
            { x: -1.4, y: rulebookZone.y + 0.5 },
        ],
    },
    {
        value: 'OOZ-Left-Middle',
        group: 'Out-Of-Zone',
        text: 'Left Middle',
        data: [
            { x: -1.4, y: rulebookZone.y - 0.15 },
            { x: -0.9, y: rulebookZone.y - 0.15 },
            { x: -0.9, y: rulebookZone.y2 + 0.15 },
            { x: -1.4, y: rulebookZone.y2 + 0.15 },
            { x: -1.4, y: rulebookZone.y - 0.15 },
        ],
    },
    {
        value: 'OOZ-Left-Down',
        group: 'Out-Of-Zone',
        text: 'Left Down',
        data: [
            { x: -0.9, y: rulebookZone.y2 + 0.15 },
            { x: -1.4, y: rulebookZone.y2 + 0.15 },
            { x: -1.4, y: rulebookZone.y2 - 0.5 },
            { x: -(rulebookZone.width / 3), y: rulebookZone.y2 - 0.5 },
            { x: -(rulebookZone.width / 3), y: rulebookZone.y2 - 0.1 },
            { x: -0.9, y: rulebookZone.y2 - 0.1 },
            { x: -0.9, y: rulebookZone.y2 + 0.15 },
        ],
    },
    {
        value: 'OOZ-Right-Up',
        group: 'Out-Of-Zone',
        text: 'Right Up',
        data: [
            { x: 1.4, y: rulebookZone.y + 0.5 },
            { x: rulebookZone.width / 3, y: rulebookZone.y + 0.5 },
            { x: rulebookZone.width / 3, y: rulebookZone.y + 0.1 },
            { x: 0.9, y: rulebookZone.y + 0.1 },
            { x: 0.9, y: rulebookZone.y - 0.15 },
            { x: 1.4, y: rulebookZone.y - 0.15 },
            { x: 1.4, y: rulebookZone.y + 0.5 },
        ],
    },
    {
        value: 'OOZ-Right-Middle',
        group: 'Out-Of-Zone',
        text: 'Right Middle',
        data: [
            { x: 1.4, y: rulebookZone.y - 0.15 },
            { x: 0.9, y: rulebookZone.y - 0.15 },
            { x: 0.9, y: rulebookZone.y2 + 0.15 },
            { x: 1.4, y: rulebookZone.y2 + 0.15 },
            { x: 1.4, y: rulebookZone.y - 0.15 },
        ],
    },
    {
        value: 'OOZ-Right-Down',
        group: 'Out-Of-Zone',
        text: 'Right Down',
        data: [
            { x: 0.9, y: rulebookZone.y2 + 0.15 },
            { x: 1.4, y: rulebookZone.y2 + 0.15 },
            { x: 1.4, y: rulebookZone.y2 - 0.5 },
            { x: rulebookZone.width / 3, y: rulebookZone.y2 - 0.5 },
            { x: rulebookZone.width / 3, y: rulebookZone.y2 - 0.1 },
            { x: 0.9, y: rulebookZone.y2 - 0.1 },
            { x: 0.9, y: rulebookZone.y2 + 0.15 },
        ],
    },
    {
        value: 'OOZ-Middle-Down',
        group: 'Out-Of-Zone',
        text: 'Middle Down',
        data: [
            { x: -(rulebookZone.width / 3), y: rulebookZone.y2 - 0.5 },
            { x: rulebookZone.width / 3, y: rulebookZone.y2 - 0.5 },
            { x: rulebookZone.width / 3, y: rulebookZone.y2 - 0.1 },
            { x: -(rulebookZone.width / 3), y: rulebookZone.y2 - 0.1 },
            { x: -(rulebookZone.width / 3), y: rulebookZone.y2 - 0.5 },
        ],
    },
    {
        value: 'OOZ-Far-Left-Middle',
        group: 'Out-Of-Zone',
        text: 'Far Left Middle',
        data: [
            { x: -2, y: rulebookZone.y - (rulebookZone.y - rulebookZone.y2) / 4 },
            { x: -1.4, y: rulebookZone.y - (rulebookZone.y - rulebookZone.y2) / 4 },
            { x: -1.4, y: rulebookZone.y2 - pitchZoneOffset },
            { x: -2, y: rulebookZone.y2 - pitchZoneOffset },
            { x: -2, y: rulebookZone.y - (rulebookZone.y - rulebookZone.y2) / 4 },
        ],
    },
    {
        value: 'OOZ-Far-Left-Down',
        group: 'Out-Of-Zone',
        text: 'Far Left Down',
        data: [
            { x: -2, y: rulebookZone.y2 - pitchZoneOffset },
            { x: -1.4, y: rulebookZone.y2 - pitchZoneOffset },
            { x: -1.4, y: rulebookZone.y2 - 0.5 },
            { x: -0.9, y: rulebookZone.y2 - 0.5 },
            { x: -0.9, y: rulebookZone.y2 - 1 },
            { x: -2, y: rulebookZone.y2 - 1 },
            { x: -2, y: rulebookZone.y2 - pitchZoneOffset },
        ],
    },
    {
        value: 'OOZ-Far-Right-Middle',
        group: 'Out-Of-Zone',
        text: 'Far Right Middle',
        data: [
            { x: 2, y: rulebookZone.y - (rulebookZone.y - rulebookZone.y2) / 4 },
            { x: 1.4, y: rulebookZone.y - (rulebookZone.y - rulebookZone.y2) / 4 },
            { x: 1.4, y: rulebookZone.y2 - pitchZoneOffset },
            { x: 2, y: rulebookZone.y2 - pitchZoneOffset },
            { x: 2, y: rulebookZone.y - (rulebookZone.y - rulebookZone.y2) / 4 },
        ],
    },
    {
        value: 'OOZ-Far-Right-Down',
        group: 'Out-Of-Zone',
        text: 'Far Right Down',
        data: [
            { x: 2, y: rulebookZone.y2 - pitchZoneOffset },
            { x: 1.4, y: rulebookZone.y2 - pitchZoneOffset },
            { x: 1.4, y: rulebookZone.y2 - 0.5 },
            { x: 0.9, y: rulebookZone.y2 - 0.5 },
            { x: 0.9, y: rulebookZone.y2 - 1 },
            { x: 2, y: rulebookZone.y2 - 1 },
            { x: 2, y: rulebookZone.y2 - pitchZoneOffset },
        ],
    },
    {
        value: 'OOZ-Far-Middle-Down',
        group: 'Out-Of-Zone',
        text: 'Far Down',
        data: [
            { x: -0.9, y: rulebookZone.y2 - 0.5 },
            { x: 0.9, y: rulebookZone.y2 - 0.5 },
            { x: 0.9, y: rulebookZone.y2 - 1.5 },
            { x: -0.9, y: rulebookZone.y2 - 1.5 },
            { x: -0.9, y: rulebookZone.y2 - 0.5 },
        ],
    },
    {
        value: 'IZ-Left-Up',
        group: 'In-Zone',
        text: 'Left Up',
        data: [
            { x: -0.9, y: rulebookZone.y + 0.1 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y + 0.1 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y - 0.4 },
            { x: -0.9, y: rulebookZone.y - 0.4 },
            { x: -0.9, y: rulebookZone.y + 0.1 },
        ],
    },
    {
        value: 'IZ-Left-Middle',
        group: 'In-Zone',
        text: 'Left Middle',
        data: [
            { x: -0.9, y: rulebookZone.y - 0.4 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y - 0.4 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y2 + 0.4 },
            { x: -0.9, y: rulebookZone.y2 + 0.4 },
            { x: -0.9, y: rulebookZone.y - 0.4 },
        ],
    },
    {
        value: 'IZ-Left-Down',
        group: 'In-Zone',
        text: 'Left Down',
        data: [
            { x: -0.9, y: rulebookZone.y2 + 0.4 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y2 + 0.4 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y2 - 0.1 },
            { x: -0.9, y: rulebookZone.y2 - 0.1 },
            { x: -0.9, y: rulebookZone.y2 + 0.4 },
        ],
    },
    {
        value: 'IZ-Middle-Up',
        group: 'In-Zone',
        text: 'Middle Up',
        data: [
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y + pitchZoneOffset },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y + pitchZoneOffset },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y - 0.3 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y - 0.3 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y + pitchZoneOffset },
        ],
    },
    {
        value: 'IZ-Middle-Middle',
        group: 'In-Zone',
        text: 'Middle Middle',
        data: [
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y - 0.3 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y - 0.3 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y2 + 0.3 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y2 + 0.3 },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y - 0.3 },
        ],
    },
    {
        value: 'IZ-Middle-Down',
        group: 'In-Zone',
        text: 'Middle Down',
        data: [
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y2 + 0.3 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y2 + 0.3 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y2 - pitchZoneOffset },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y2 - pitchZoneOffset },
            { x: -(rulebookZone.width / 6) - pitchZoneOffset, y: rulebookZone.y2 + 0.3 },
        ],
    },
    {
        value: 'IZ-Right-Up',
        group: 'In-Zone',
        text: 'Right Up',
        data: [
            { x: 0.9, y: rulebookZone.y + 0.1 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y + 0.1 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y - 0.4 },
            { x: 0.9, y: rulebookZone.y - 0.4 },
            { x: 0.9, y: rulebookZone.y + 0.1 },
        ],
    },
    {
        value: 'IZ-Right-Middle',
        group: 'In-Zone',
        text: 'Right Middle',
        data: [
            { x: 0.9, y: rulebookZone.y - 0.4 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y - 0.4 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y2 + 0.4 },
            { x: 0.9, y: rulebookZone.y2 + 0.4 },
            { x: 0.9, y: rulebookZone.y - 0.4 },
        ],
    },
    {
        value: 'IZ-Right-Down',
        group: 'In-Zone',
        text: 'Right Down',
        data: [
            { x: 0.9, y: rulebookZone.y2 + 0.4 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y2 + 0.4 },
            { x: rulebookZone.width / 6 + pitchZoneOffset, y: rulebookZone.y2 - 0.1 },
            { x: 0.9, y: rulebookZone.y2 - 0.1 },
            { x: 0.9, y: rulebookZone.y2 + 0.4 },
        ],
    },
];

// https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio-i-need-an-output-like-43-169
function greatestCommonDivisor(a: number, b: number): number {
    return b === 0 ? a : greatestCommonDivisor(b, a % b);
}

function isSignalRef(value: any): value is SignalRef {
    return isPlainObject(value) && isString(value.signal);
}

export function getDefaultSpec({
    width,
    height,
    minX = 0,
    maxX = 100,
    minY = 0,
    maxY = 100,
    defaultWidth = 400,
    backgroundColor = 'white',
}: StlcDefaultSpecOptions): Spec {
    const aspectRatio = (maxX - minX) / (maxY - minY);

    if (isNumber(defaultWidth) && isFinite(defaultWidth) && isNil(width) && isNil(height)) {
        width = defaultWidth;
        height = defaultWidth / aspectRatio;
    } else if (isNumber(height) && isFinite(height) && isNil(width) && defaultWidth !== 'fit') {
        width = height * aspectRatio;
    } else if (isNumber(width) && isFinite(width) && isNil(height) && defaultWidth !== 'fit') {
        height = width / aspectRatio;
    } else if (
        isNumber(defaultWidth) &&
        isFinite(defaultWidth) &&
        isNumber(width) &&
        isFinite(width) &&
        isNumber(height) &&
        isFinite(height) &&
        width / height !== aspectRatio
    ) {
        const gcd = greatestCommonDivisor(defaultWidth, defaultWidth / aspectRatio);
        const gcd2 = greatestCommonDivisor(width, height);
        throw new Error(
            `Chart aspect ratio is ${width / gcd2}:${height / gcd2} but it should be ${defaultWidth / gcd}:${
                defaultWidth / aspectRatio / gcd
            }.  Provide only the width or height to scale automatically.`
        );
    }

    return {
        $schema: 'https://vega.github.io/schema/vega/v5.json',
        width,
        height,
        autosize: {
            type: isNumber(defaultWidth)
                ? 'none'
                : isSignalRef(defaultWidth) || isSignalRef(width) || isSignalRef(height)
                ? 'pad'
                : defaultWidth,
            contains: 'padding',
        },
        usermeta: {
            resize:
                defaultWidth === 'fit' ||
                defaultWidth === 'fit-x' ||
                defaultWidth === 'fit-y' ||
                isSignalRef(width) ||
                isSignalRef(height),
        },
        background: backgroundColor,
        config: {
            title: {
                font: defaultFont,
                fontSize: 16,
                fontWeight: 'normal',
                color: '#333333',
            },
            axis: {
                domainColor: '#aaaaaa',
                domainOpacity: 1,
                gridColor: '#dddddd',
                gridOpacity: 1,
                labelColor: '#333333',
                labelFont: defaultFont,
                labelFontSize: 11,
                labelFontWeight: 'normal',
                tickColor: '#999999',
                titleFont: defaultFont,
                titleFontSize: 16,
                titleFontWeight: 'normal',
                titleColor: '#333333',
                titlePadding: 16,
            },
            text: {
                font: defaultFont,
            },
            events: {
                defaults: { allow: ['wheel', 'touchstart'] },
            },
        },
    };
}

interface InfieldPositioning {
    batterBats: 'R' | 'L';
    fielder: number;
    value: number;
    label: string;
    launchDistance: number;
    horizontalAngle: number;
}

export const infieldPositioning: InfieldPositioning[] = [
    {
        batterBats: 'R',
        fielder: 5,
        value: 2,
        label: 'Pull',
        launchDistance: 117.76450781114,
        horizontalAngle: -39.7669490694577,
    },
    {
        batterBats: 'R',
        fielder: 5,
        value: 1,
        label: 'Step to Line',
        launchDistance: 112.418498477786,
        horizontalAngle: -38.9559078377589,
    },
    {
        batterBats: 'R',
        fielder: 5,
        value: 0,
        label: 'Straight',
        launchDistance: 105.961065491057,
        horizontalAngle: -36.4340692270084,
    },
    {
        batterBats: 'R',
        fielder: 5,
        value: -1,
        label: 'Step to Hole',
        launchDistance: 104.377971334952,
        horizontalAngle: -33.7372633798137,
    },
    {
        batterBats: 'R',
        fielder: 6,
        value: 4,
        label: 'SHIFT to Hole',
        launchDistance: 139.195178077403,
        horizontalAngle: -27.021581591177,
    },
    {
        batterBats: 'R',
        fielder: 6,
        value: 2,
        label: 'Pull',
        launchDistance: 140.525199464234,
        horizontalAngle: -23.9692914436648,
    },
    {
        batterBats: 'R',
        fielder: 6,
        value: 1,
        label: 'Step to Hole',
        launchDistance: 141.267853738917,
        horizontalAngle: -21.4978894512248,
    },
    {
        batterBats: 'R',
        fielder: 6,
        value: 0,
        label: 'Straight',
        launchDistance: 142.635376046758,
        horizontalAngle: -18.6318407609939,
    },
    {
        batterBats: 'R',
        fielder: 6,
        value: -1,
        label: 'Step to Middle',
        launchDistance: 142.183249716695,
        horizontalAngle: -13.4908744121242,
    },
    {
        batterBats: 'R',
        fielder: 4,
        value: 4,
        label: 'SHIFT past 2B',
        launchDistance: 145.807141457475,
        horizontalAngle: -9.42226924040591,
    },
    {
        batterBats: 'R',
        fielder: 4,
        value: 3,
        label: 'SHIFT near 2B',
        launchDistance: 151.097256427772,
        horizontalAngle: 2.35168793124273,
    },
    {
        batterBats: 'R',
        fielder: 4,
        value: 2,
        label: 'Pull',
        launchDistance: 150.240986463536,
        horizontalAngle: 4.62759170251172,
    },
    {
        batterBats: 'R',
        fielder: 4,
        value: 1,
        label: 'Step to Middle',
        launchDistance: 148.420087926129,
        horizontalAngle: 7.19923363836765,
    },
    {
        batterBats: 'R',
        fielder: 4,
        value: 0,
        label: 'Straight',
        launchDistance: 147.081197982611,
        horizontalAngle: 9.46232220802562,
    },
    {
        batterBats: 'R',
        fielder: 4,
        value: -1,
        label: 'Step to Hole',
        launchDistance: 145.930668469654,
        horizontalAngle: 12.2647737278924,
    },
    {
        batterBats: 'R',
        fielder: 3,
        value: 2,
        label: 'Pull',
        launchDistance: 122.415855999131,
        horizontalAngle: 26.6299387818548,
    },
    {
        batterBats: 'R',
        fielder: 3,
        value: 1,
        label: 'Step to Hole',
        launchDistance: 119.085603244053,
        horizontalAngle: 29.3008802692709,
    },
    {
        batterBats: 'R',
        fielder: 3,
        value: 0,
        label: 'Straight',
        launchDistance: 118.298615799172,
        horizontalAngle: 31.6075022462489,
    },
    {
        batterBats: 'R',
        fielder: 3,
        value: -1,
        label: 'Step to Line',
        launchDistance: 117.029413824047,
        horizontalAngle: 34.531972827906,
    },
    {
        batterBats: 'L',
        fielder: 5,
        value: -1,
        label: 'Step to Line',
        launchDistance: 116.586379993548,
        horizontalAngle: -29.2913621709843,
    },
    {
        batterBats: 'L',
        fielder: 5,
        value: 0,
        label: 'Straight',
        launchDistance: 130.604217772628,
        horizontalAngle: -25.8960225888745,
    },
    {
        batterBats: 'L',
        fielder: 5,
        value: 1,
        label: 'Step to Hole',
        launchDistance: 131.877736857584,
        horizontalAngle: -22.153344737778,
    },
    {
        batterBats: 'L',
        fielder: 5,
        value: 2,
        label: 'Pull',
        launchDistance: 134.315475887759,
        horizontalAngle: -18.9813497740127,
    },
    {
        batterBats: 'L',
        fielder: 5,
        value: 4,
        label: 'SHIFT to Hole',
        launchDistance: 142.823563882155,
        horizontalAngle: -16.3895403340348,
    },
    {
        batterBats: 'L',
        fielder: 5,
        value: 5,
        label: 'SHIFT past 2B',
        launchDistance: 152.650713997628,
        horizontalAngle: 5.87206295726098,
    },
    {
        batterBats: 'L',
        fielder: 6,
        value: -1,
        label: 'Step to Hole',
        launchDistance: 144.219649840096,
        horizontalAngle: -12.0345453507026,
    },
    {
        batterBats: 'L',
        fielder: 6,
        value: 0,
        label: 'Straight',
        launchDistance: 148.153407655713,
        horizontalAngle: -9.7579634413478,
    },
    {
        batterBats: 'L',
        fielder: 6,
        value: 1,
        label: 'Step to Middle',
        launchDistance: 149.543755804112,
        horizontalAngle: -7.74376903332608,
    },
    {
        batterBats: 'L',
        fielder: 6,
        value: 2,
        label: 'Pull',
        launchDistance: 151.119833245011,
        horizontalAngle: -4.47082083549518,
    },
    {
        batterBats: 'L',
        fielder: 6,
        value: 3,
        label: 'SHIFT near 2B',
        launchDistance: 152.841318039331,
        horizontalAngle: -0.697277293067034,
    },
    {
        batterBats: 'L',
        fielder: 6,
        value: 4,
        label: 'SHIFT past 2B',
        launchDistance: 152.650713997628,
        horizontalAngle: 5.87206295726098,
    },
    {
        batterBats: 'L',
        fielder: 4,
        value: -1,
        label: 'Step to Middle',
        launchDistance: 149.677361347667,
        horizontalAngle: 15.6193921387108,
    },
    {
        batterBats: 'L',
        fielder: 4,
        value: 0,
        label: 'Straight',
        launchDistance: 148.066258043189,
        horizontalAngle: 19.2386445169084,
    },
    {
        batterBats: 'L',
        fielder: 4,
        value: 1,
        label: 'Step to Hole',
        launchDistance: 146.343970494175,
        horizontalAngle: 22.545176015912,
    },
    {
        batterBats: 'L',
        fielder: 4,
        value: 2,
        label: 'Pull',
        launchDistance: 143.917479133009,
        horizontalAngle: 25.7923225847816,
    },
    {
        batterBats: 'L',
        fielder: 4,
        value: 4,
        label: 'SHIFT to Hole',
        launchDistance: 173.469315230235,
        horizontalAngle: 25.8104154438463,
    },
    {
        batterBats: 'L',
        fielder: 3,
        value: -1,
        label: 'Step to Hole',
        launchDistance: 114.44375430752,
        horizontalAngle: 34.2927056424496,
    },
    {
        batterBats: 'L',
        fielder: 3,
        value: 0,
        label: 'Straight',
        launchDistance: 111.848154656212,
        horizontalAngle: 36.7746165038875,
    },
    {
        batterBats: 'L',
        fielder: 3,
        value: 1,
        label: 'Step to Line',
        launchDistance: 115.612961211103,
        horizontalAngle: 38.4678633121476,
    },
    {
        batterBats: 'L',
        fielder: 3,
        value: 2,
        label: 'Pull',
        launchDistance: 124.241951449581,
        horizontalAngle: 40.4462320208414,
    },
];

export const axes: StlcChartAxis[] = [
    {
        field: 'velo',
        title: 'Velocity (MPH)',
        label: 'Velocity',
        domainMin: 70,
        domainMax: 100,
        tickMinStep: 5,
        clamp: true,
        zero: false,
        grid: true,
    },
    {
        field: 'pitcherPitchOfGame',
        title: 'Pitch Of Game',
        heading: 'Pitch Of Game',
        label: 'Pitch',
        zero: true,
        nice: true,
        round: true,
        tickCount: 5,
        domainMin: 1,
        domainMax: {
            data: 'data',
            field: 'pitcherPitchOfGame',
        },
    },
    {
        field: 'releaseExtension',
        title: 'Extension (feet)',
        label: 'Extension',
        titleOffset: 40,
        format: '.2f',
        domainMin: 4,
        tickMinStep: 0.5,
        clamp: true,
        zero: false,
        grid: true,
        unit: 'feet',
    },
    {
        field: 'verticalBreakAn',
        title: 'Vertical Break (inches)',
        heading: 'Vertical Break',
        titleOffset: 40,
        label: 'Vert Break',
        format: '.0f',
        domainMin: -30,
        domainMax: 30,
        tickMinStep: 5,
        sizeField: 'pitches',
        grid: true,
        unit: 'inches',
    },
    {
        field: 'horizontalBreakAn',
        title: 'Horizontal Break (inches)',
        heading: 'Horizontal Break',
        titleOffset: 40,
        label: 'Horiz Break',
        format: '.0f',
        tickMinStep: 5,
        sizeField: 'pitches',
        grid: true,
        unit: 'inches',
    },
    {
        field: 'relHeight',
        title: 'Release Height (feet)',
        heading: 'Release Height',
        titleOffset: 40,
        label: 'Rel Height',
        sizeField: 'pitches',
        tickCount: 4,
        grid: true,
        nice: true,
        zero: false,
        clamp: true,
        unit: 'feet',
    },
    {
        field: 'relSide',
        title: 'Release Side (feet)',
        heading: 'Release Side',
        label: 'Rel Side',
        titleOffset: 40,
        sizeField: 'pitches',
        tickMinStep: 0.5,
        clamp: true,
        grid: true,
        nice: true,
        unit: 'feet',
    },
    {
        field: 'spinRate',
        title: 'Spin Rate (RPM)',
        label: 'Spin Rate',
        titleOffset: 50,
        format: '.0f',
        sizeField: 'pitches',
        tickCount: 5,
        grid: true,
        zero: false,
    },
    {
        field: 'spinAxisDegrees',
        title: 'Spin Axis (degrees)',
        label: 'Spin Axis',
        titleOffset: 40,
        sizeField: 'pitches',
        grid: true,
        zero: false,
        unit: 'degrees',
    },
    {
        field: 'plateHeight',
        title: 'Plate Height (feet)',
        label: 'Plate Height',
        titleOffset: 40,
        domainMin: 0,
        domainMax: 4,
        clamp: true,
        zero: false,
        unit: 'feet',
    },
    {
        field: 'plateSide',
        title: 'Plate Side (feet)',
        label: 'Plate Side',
        domainMin: -2,
        domainMax: 2,
        sizeField: 'pitches',
        titleOffset: 40,
        clamp: true,
        zero: true,
        unit: 'feet',
    },
];

export function getPitchTypeScales(values: any[], pitchField = 'name'): Scale[] {
    return [
        {
            name: 'pitchColor',
            type: 'ordinal',
            domain: map(values, pitchField),
            range: map(values, 'color'),
        },
        {
            name: 'pitchTypeAbbrev',
            type: 'ordinal',
            domain: map(values, pitchField),
            range: map(values, 'abbrev'),
        },
        {
            name: 'pitchTypeColor',
            type: 'ordinal',
            domain: map(values, pitchField),
            range: map(values, 'color'),
        },
        {
            name: 'pitchZones',
            type: 'ordinal',
            domain: map(values, pitchField),
            range: map(values, 'color'),
        },
    ];
}

export const scales: Scale[] = [
    ...getPitchTypeScales(pitchTypes),
    {
        name: 'slgColor',
        type: 'ordinal',
        domain: [0, 1, 2, 3, 4],
        range: ['#450d54', '#39558c', '#1f968b', '#74d055', '#fde725'],
    },
    {
        name: 'bipColor',
        type: 'ordinal',
        domain: ['hardHit', 'hit', 'buntHit', 'out', 'hardOut', 'buntOut'],
        range: ['#852528', 'black', '#2f78b3', 'black', '#852528', '#2f78b3'],
    },
    {
        name: 'bipFillColor',
        type: 'ordinal',
        domain: ['hardHit', 'hit', 'buntHit', 'out', 'hardOut', 'buntOut'],
        range: ['#852528', 'black', '#2f78b3', 'white', 'white', 'white'],
    },
    {
        name: 'estSlgColor',
        type: 'threshold',
        domain: [0.35, 0.75],
        range: ['#3B3D96', '#FDA428', '#FC291C'],
    },
    {
        name: 'estSlgLabel',
        type: 'threshold',
        domain: [0.35, 0.75],
        range: ['xSLG < .350', 'xSLG .350 - .750', 'xSLG > .750'],
    },
    {
        name: 'pitcherThrows',
        type: 'ordinal',
        domain: ['L', 'R'],
        range: ['LHP', 'RHP'],
    },
    {
        name: 'pitcherSituationLabel',
        type: 'ordinal',
        domain: ['Get Ahead', 'Limit Damage', 'Put Away'],
        range: ['Get Ahead', 'Limit Damage', 'Put Away'],
    },
    {
        name: 'batterBatsLabel',
        type: 'ordinal',
        domain: ['L', 'R', 'B'],
        range: ['LHB', 'RHB', 'SHB'],
    },
    {
        name: 'pitcherThrowsLabel',
        type: 'ordinal',
        domain: ['L', 'R', 'B'],
        range: ['LHP', 'RHP', 'SHP'],
    },
    {
        name: 'fielderColor',
        type: 'ordinal',
        domain: [3, 4, 5, 6, 7, 8, 9],
        range: ['#f8766d', '#7cae00', '#00bfc4', '#c77cff', '#f8766d', '#7cae00', '#00bfc4'],
    },
    {
        name: 'infieldPositioningX',
        type: 'ordinal',
        domain: map(
            infieldPositioning,
            (datum: InfieldPositioning) => `${datum.batterBats}:${datum.fielder}:${datum.label}`
        ),
        range: map(infieldPositioning, (datum: InfieldPositioning) =>
            Math.round(Math.sin((datum.horizontalAngle * Math.PI) / 180) * datum.launchDistance)
        ),
    },
    {
        name: 'infieldPositioningY',
        type: 'ordinal',
        domain: map(
            infieldPositioning,
            (datum: InfieldPositioning) => `${datum.batterBats}:${datum.fielder}:${datum.label}`
        ),
        range: map(infieldPositioning, (datum: InfieldPositioning) =>
            Math.round(Math.cos((datum.horizontalAngle * Math.PI) / 180) * datum.launchDistance)
        ),
    },
    {
        name: 'infieldPositioningValue',
        type: 'ordinal',
        domain: map(
            infieldPositioning,
            (datum: InfieldPositioning) => `${datum.batterBats}:${datum.fielder}:${datum.label}`
        ),
        range: map(infieldPositioning, 'value'),
    },
];

// convert scales to signal options
// export const signals: StlcChartSignal[] = transform(
//     ['batterBats', 'pitcherThrows'],
//     (result: StlcChartSignal[], field: string) => {
//         chain(scales)
//             .filter({ name: `${field}Label` })
//             .forEach((datum: Scale) => {
//                 if (datum.type === 'ordinal' && isArray(datum.domain) && isArray(datum.range)) {
//                     result.push({
//                         field,
//                         options: map(datum.domain, (value: string, index: number) => ({
//                             value,
//                             label: datum[index] as string,
//                             title: 'vs ' + datum.range[index],
//                         })),
//                     });
//                 }
//             })
//             .valueOf();
//     },
//     []
// );
