import Joi from 'joi';
import type { Spec } from 'vega';

export interface BrushingOptions {
    xField?: string;
    yField?: string;
    xSignal?: string;
    ySignal?: string;
    xScale?: string;
    yScale?: string;
    visibleDataName?: string;
    brushedIdsDataName?: string;
    brushedIdsDefault?: any;
}

export function addBrushing(spec: Spec, options: BrushingOptions): void {
    if (!spec.signals) {
        spec.signals = [];
    }
    spec.signals.push(
        {
            name: 'brushStart',
            value: [0, 0],
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: '{ x: clamp(x(), 0, width), y: clamp(y(), 0, height) }',
                    force: true,
                },
            ],
        },
        {
            name: 'brushEnd',
            value: [0, 0],
            on: [
                {
                    events: 'mousedown, [mousedown, window:mouseup] > window:mousemove, touchstart, [touchstart, window:touchend] > window:touchmove',
                    update: '{ x: clamp(x(), 0, width), y: clamp(y(), 0, height) }',
                    force: true,
                },
            ],
        },
        {
            name: 'brushed',
            value: false,
            on: [
                {
                    events: 'mousedown, touchstart',
                    update: 'true',
                },
                {
                    events: 'window:mouseup, window:touchend',
                    update: 'false',
                },
            ],
        },
        {
            name: 'brushX', // brushXRange
            update: `invert('${options.xScale || 'x'}', [brushStart.x, brushEnd.x])`,
        },
        {
            name: 'brushY', // brushYRange
            update: `invert('${options.yScale || 'y'}', [brushStart.y, brushEnd.y])`,
        },
        {
            name: 'brushOperation',
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: "event.shiftKey && !event.altKey ? 'add' : event.altKey && !event.shiftKey ? 'remove' : null",
                },
            ],
        },
        {
            name: options.brushedIdsDataName ?? 'brushedIds',
            value: options.brushedIdsDefault === undefined ? null : options.brushedIdsDefault,
        }
    );

    if (!spec.marks) {
        spec.marks = [];
    }
    spec.marks.push({
        name: 'brush',
        type: 'rect',
        interactive: false,
        encode: {
            enter: {
                fill: { value: '#0080FF' },
                fillOpacity: { value: 0.1 },
                strokeWidth: { value: 1 },
                stroke: { value: '#0080FF' },
                strokeOpacity: { value: 0.3 },
            },
            update: {
                x: { signal: 'brushStart.x' },
                x2: { signal: 'brushEnd.x' },
                y: { signal: 'brushStart.y' },
                y2: { signal: 'brushEnd.y' },
                fillOpacity: { signal: 'brushed ? 0.1 : 0' },
                strokeOpacity: { signal: 'brushed ? 0.3 : 0' },
            },
        },
    });

    const yField = Joi.attempt(
        options.ySignal ? `datum[${options.ySignal}]` : options.yField ? `datum.${options.yField}` : undefined,
        Joi.string().required(),
        'ySignal or yField must be specified'
    );

    const xField = Joi.attempt(
        options.xSignal ? `datum[${options.xSignal}]` : options.xField ? `datum.${options.xField}` : undefined,
        Joi.string().required(),
        'xSignal or xField must be specified'
    );

    if (!spec.data) {
        spec.data = [];
    }
    spec.data.push({
        name: 'brushedData',
        source: options.visibleDataName ?? 'visibleData',
        transform: [
            {
                type: 'filter',
                expr: `isDefined(${xField}) && isDefined(${yField})`,
            },
            {
                type: 'filter',
                expr: `inrange(${xField}, brushX) && inrange(${yField}, brushY)`,
            },
        ],
    });
}
